import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Radio, Table } from 'antd';
import { useEffect, useState } from 'react';
import IFANav from '../components/ifa_nav';
import { getApp } from 'firebase/app';
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { Link, useSearchParams } from 'react-router-dom';
import splitPhoneNumber from '../utils/format/split_phone_number';
import getTaskLabel from '../utils/product/get_task_label';
import products from '../configs/products';
import taskStates from '../configs/task_states';
import uploadFiles from '../utils/firebase_storage/upload_files';
import uploadEstimates from '../utils/firebase_firestore/upload_estimates';
import uploadContract from 'utils/firebase_firestore/upload_contract';
import Dropdown from 'antd/es/dropdown/dropdown';
import updateContractSubstate from 'utils/firebase_firestore/update_contract_substate';

// const columns = [
//   {
//     title: 'Status',
//     dataIndex: 'currentStatus',
//     key: 'currentStatus',
//     align: 'center',
//     width: 120,
//     render: (text: any) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '소유주',
//     dataIndex: 'policyOwner',
//     key: 'policyOwner',
//     align: 'center',
//     width: 100,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '피보험자',
//     dataIndex: 'insured',
//     key: 'insured',
//     align: 'center',
//     width: 120,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
 
//   {
//     title: '원수사',
//     dataIndex: 'provider',
//     key: 'provider',
//     align: 'center',
//     width: 100,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '증권번호',
//     dataIndex: 'policyNo',
//     key: 'policyNo',
//     align: 'center',
//     width: 120,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '신탁 설정자',
//     dataIndex: 'settlor',
//     key: 'settlor',
//     align: 'center',
//     width: 140,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '송금인',
//     dataIndex: 'payor',
//     key: 'payor',
//     align: 'center',
//     width: 100,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '상품',
//     dataIndex: 'product',
//     key: 'product',
//     align: 'center',
//     width: 80,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '통화',
//     dataIndex: 'currency',
//     key: 'currency',
//     align: 'center',
//     width: 80,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '보험료',
//     dataIndex: 'premium',
//     key: 'premium',
//     align: 'center',
//     width: 100,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '선납 연수',
//     dataIndex: 'prepaid',
//     key: 'prepaid',
//     align: 'center',
//     width: 120,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '납입방법',
//     dataIndex: 'paymentMathod',
//     key: 'paymentMathod',
//     align: 'center',
//     width: 120,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '납입일',
//     dataIndex: 'paymentDate',
//     key: 'paymentDate',
//     align: 'center',
//     width: 100,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '송금증 제출일',
//     dataIndex: 'submissionDate',
//     key: 'submissionDate',
//     align: 'center',
//     width: 160,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '청약 서류 접수일',
//     dataIndex: 'receiptDate',
//     key: 'receiptDate',
//     align: 'center',
//     width: 180,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '증권 발효일',
//     dataIndex: 'effectiveDate',
//     key: 'effectiveDate',
//     align: 'center',
//     width: 140,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '증권 개시일',
//     dataIndex: 'issueDate',
//     key: 'issueDate',
//     align: 'center',
//     width: 140,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '증권 발송일',
//     dataIndex: 'deliveryDate',
//     key: 'deliveryDate',
//     align: 'center',
//     width: 140,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '소개자',
//     dataIndex: 'introducer',
//     key: 'introducer',
//     align: 'center',
//     width: 100,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '소개자 코드',
//     dataIndex: 'introducerCode',
//     key: 'introducerCode',
//     align: 'center',
//     width: 140,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '커미션 지급 예정일',
//     dataIndex: 'dueDate',
//     key: 'dueDate',
//     align: 'center',
//     width: 200,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
//   {
//     title: '커미션 지급일',
//     dataIndex: 'disbursementDate',
//     key: 'disbursementDate',
//     align: 'center',
//     width: 160,
//     render: (text) => <div className='els1'>{text}</div>,
//   },
// ];

const columns1 = [
  {
    title: 'Status',
    dataIndex: 'joinStep',
    key: 'joinStep',
    align: 'center',
    width: 80,
    render: (step: any) => <div className='els1'>{getTaskLabel(step.split('_')[0], Number(step.split('_')[1]), step.split('_')[0] === taskStates.CONTRACT)}</div>,
  },
  {
    title: '고객명',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 80,
    render: (text: any) => <div className='els1'>{text}</div>,
  },
  {
    title: '이메일',
    dataIndex: 'userEmail',
    key: 'userEmail',
    align: 'center',
    width: 120,
    render: (text: any) => <div className='els1'>{text}</div>,
  },
  {
    title: '전화번호',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    align: 'center',
    width: 80,
    render: (text: any) => <div className='els1'>{splitPhoneNumber(text)}</div>,
  },
  {
    title: '상품',
    dataIndex: 'productCode',
    key: 'productCode',
    align: 'center',
    width: 120,
    render: (text: any) => {
      const product = products.filter((p: any) => p.code === text)[0];

      return (
        <div className='els1' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <img src={product.logo} style={{width: '24px', height: '24px'}} alt='logo' />
          <div style={{marginLeft: '12px', textAlign: 'left'}}>{product.name}</div>
        </div>
      );
    },
  },
  {
    title: '요청시간',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    width: 100,
    render: (text: any) => <div className='els1'>{new Date(text).toLocaleDateString() + ' ' + new Date(text).toLocaleTimeString()}</div>,
  },
];

const columns2 = [
  {
    title: 'Status',
    dataIndex: 'step',
    key: 'step',
    align: 'center',
    width: 80,
    render: (step: any) => <div className='els1'>{getTaskLabel(step, 1, step === taskStates.CONTRACT)}</div>,
  },
  {
    title: '고객명',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 80,
    render: (text: any) => <div className='els1'>{text}</div>,
  },
  {
    title: '이메일',
    dataIndex: 'userEmail',
    key: 'userEmail',
    align: 'center',
    width: 120,
    render: (text: any) => <div className='els1'>{text}</div>,
  },
  {
    title: '전화번호',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    align: 'center',
    width: 80,
    render: (text: any) => <div className='els1'>{splitPhoneNumber(text)}</div>,
  },
  {
    title: '상품',
    dataIndex: 'productCode',
    key: 'productCode',
    align: 'center',
    width: 120,
    render: (text: any) => {
      const product = products.filter((p: any) => p.code === text)[0];

      return (
        <div className='els1' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <img src={product.logo} style={{width: '24px', height: '24px'}} alt='logo' />
          <div style={{marginLeft: '12px', textAlign: 'left'}}>{product.name}</div>
        </div>
      );
    },
  },
  {
    title: '요청시간',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    width: 100,
    render: (text: any) => <div className='els1'>{new Date(text).toLocaleDateString() + ' ' + new Date(text).toLocaleTimeString()}</div>,
  },
];

const IFAPage = () => {
  const [tasks, setTasks] = useState<any>([]);

  useEffect(() => {
    req();
  }, []);

  const req = async () => {
    const app = getApp();
    const firestore = getFirestore(app);

    const d = (await getDocs(query(collection(firestore, 'kr_user_tasks'), where('forIfa', '==', true)))).docs.map((d: any) => ({
      id: d.id,
      ...d.data(),
    }));
    
    setTasks(d);
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    setModalMode('edit');
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [selectedData, setSelectedData] = useState<any>(false);

  const handleRowClick = (data: any) => {
    setSelectedData(data);
    showModal();
  };

//modal mode
  const [modalMode, setModalMode] = useState(''); // add, edit

  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState('');

  useEffect(() => {
    if (searchParams.get('tab')) {
      setTab(searchParams.get('tab') ?? '');
    } else {
      setTab('ESTIMATE');
    }
  }, [searchParams]);

  const [files, setFiles] = useState<any>([]);

  const handleFiles = (e: any) => {
    if (e.target.files.length > 0) {
      setFiles(Array.from(e.target.files));
    }
  }

  const handleOk = async () => {
    setLoading(true);

    if (selectedData.step === taskStates.ESTIMATE) {
      const fs = await uploadFiles(selectedData.id, files);
      await uploadEstimates(selectedData.id, fs);
    }

    if (selectedData.step === taskStates.CONTRACT_WRITE) {
      const fs = await uploadFiles(selectedData.id, files);
      await uploadContract(selectedData.id, fs);
    }

    if (selectedData.step === taskStates.CONTRACT) {
      if (subStep !== 5) {
        await updateContractSubstate(selectedData.id, taskStates.CONTRACT, subStep, {
          insuranceNo,
          contractDate,
          limitCancelDate,
          fee,
          feeDate,
        });
      } else {
        await updateContractSubstate(selectedData.id, taskStates.COMPLETE, 1, {
          insuranceNo,
          contractDate,
          limitCancelDate,
          fee,
          feeDate,
        });
      }
    }

    if (selectedData.step === taskStates.COMPLETE) {

    }
  
    setLoading(false);
    setIsModalOpen(false);

    req();
  };

  useEffect(() => {
    if (isModalOpen === false) {
      setLoading(false);
      setSelectedData(null);
      setFiles([]);
      
      if (document.getElementById('files')) {
        (document.getElementById('files') as any).value = null;
      }
    }
  }, [isModalOpen]);

  const [subStep, setSubStep] = useState(1);
  
  useEffect(() => {
    if (selectedData) {
      setSubStep(selectedData.subStep);

      setInsuranceNo(selectedData.insuranceNo);
      setContractDate(selectedData.contractDate);
      setLimitCancelDate(selectedData.limitCancelDate);
      setFee(selectedData.fee);
      setFeeDate(selectedData.feeDate);
    }
  }, [selectedData]);

  const [insuranceNo, setInsuranceNo] = useState('');
  const [contractDate, setContractDate] = useState('');
  const [limitCancelDate, setLimitCancelDate] = useState('');
  const [fee, setFee] = useState('');
  const [feeDate, setFeeDate] =useState('');

  return (
    <div className='page-container-ifa'>
      <Modal
        title="고객정보"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[
          <Button key="back" onClick={handleCancel}>
            취소
          </Button>,
          <Button type="primary" loading={loading} onClick={handleOk}>
            저장
          </Button>
        ]}
      >
        {
          !selectedData ? null : (
            <div className='modal-body'>
              <div>고객 기본 정보</div>
              <div className='modal-body-split'>
                <div className='modal-body' style={{width: '210px'}}>
                  <div className='input-label'>고객명</div>
                  <Input value={selectedData.name} />
                </div>
                <div className='divider' />
                <div className='modal-body' style={{width: '210px'}}>
                  <div className='input-label'>전화번호</div>
                  <Input value={selectedData.phoneNumber} />
                </div>
                <div className='divider' />
                <div className='modal-body' style={{width: '210px'}}>
                  <div className='input-label'>이메일</div>
                  <Input value={selectedData.userEmail} />
                </div>
              </div>
              {
                tab === taskStates.ESTIMATE ? (
                  <>
                    <div className='dividerR'/>
                    <div>고객 설계서 요청정보</div>
                    <div className='modal-body-split'>
                      <div className='modal-body' style={{width: '210px'}}>
                        <div className='input-label'>원수사</div>
                        <Input value={products.filter((p: any) => p.code === selectedData.productCode)[0].company} />
                        <div className='input-label'>상품명</div>
                        <Input value={products.filter((p: any) => p.code === selectedData.productCode)[0].name} />
                        <div className='input-label'>연납 보험료 (USD)</div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        {
                          selectedData.estimateReqInfo.payments.map((p: any, i: number) => {
                            return (
                              <Input key={String(i)} value={p} />
                            );
                          })
                        }
                        </div>
                      </div>
                      <div className='divider' />
                      <div className='modal-body' style={{width: '210px'}}>
                        <div className='input-label'>영문이름</div>
                        <Input value={selectedData.estimateReqInfo.enName} />
                        <div className='input-label'>성별</div>
                        <Input value={selectedData.estimateReqInfo.gender === 0 ? '남성' : '여성'} />
                        <div className='input-label'>생년월일</div>
                        <Input value={`${selectedData.estimateReqInfo.birth.substring(0, 4)}-${selectedData.estimateReqInfo.birth.substring(4, 6)}-${selectedData.estimateReqInfo.birth.substring(6)}`} />
                        <div className='input-label'>흡연여부</div>
                        <Input value={selectedData.estimateReqInfo.isSmoke ? '흡연자' : '비흡연자'} />
                      </div>
                      <div className='divider' />
                      <div className='modal-body' style={{width: '210px'}}>
                        <div className='input-label'>인출 시작 시기(피보험자 연령)</div>
                        <Input value={selectedData.estimateReqInfo.withdrawalStartAge} />
                        <div className='input-label'>인출 시작 시기(플랜 연도)</div>
                        <Input value={selectedData.estimateReqInfo.withdrawalStartYear} />
                        <div className='input-label'>인출 종료시점의 연령</div>
                        <Input value={selectedData.estimateReqInfo.withdrawalEndYear} />
                        <div className='input-label'>연간 인출금액(USD)</div>
                        <Input value={selectedData.estimateReqInfo.withdrawalYearRecur} />
                      </div>
                    </div>
                    <div className='dividerR'/>
                    <div>설계서 업로드</div>
                    <div style={{height: '8px'}}/>
                    <input type='file' id="files" multiple onChange={handleFiles} />
                  </>
                ) :
                tab === taskStates.CONTRACT_WRITE ? (
                  <>
                    <div className='dividerR'/>
                    <div>고객 청약서 요청정보</div>
                    <div className='modal-body-split'>
                      <div className='modal-body' style={{width: '210px'}}>
                        <div className='input-label'>원수사</div>
                        <Input value={products.filter((p: any) => p.code === selectedData.productCode)[0].company} />
                        <div className='input-label'>상품명</div>
                        <Input value={products.filter((p: any) => p.code === selectedData.productCode)[0].name} />
                        <div className='input-label'>연납 보험료 (USD)</div>
                        <Input value={selectedData.contractReqInfo.yearlyPaymentUSD} />
                        <div className='input-label'>납입 방식</div>
                        <Input value={selectedData.contractReqInfo.useMonthlyPayment === true ? '월납' : '연납'} />
                        <div className='input-label'>납입 연수</div>
                        <Input value={selectedData.contractReqInfo.paymentDuration + '년'} />
                        <div className='input-label'>납입 방법</div>
                        <Input value={selectedData.contractReqInfo.paymentMethod === 0 ? '카카오 온라인 트랜스퍼' : selectedData.contractReqInfo.paymentMethod === 1 ? '은행 이체' : selectedData.contractReqInfo.paymentMethod === 2 ? '신용카드 결제' : '신용카드 결제 + 송금'} />
                        {
                          selectedData.contractReqInfo.paymentMethod > 1 ? (
                            <>
                              <div className='input-label'>카드 종류</div>
                              <Input value={selectedData.contractReqInfo.cardInfoType === 0 ? 'Visa' : selectedData.contractReqInfo.cardType === 1 ? 'Master' : 'American Express'} />
                              <div className='input-label'>신용카드 번호</div>
                              <Input value={selectedData.contractReqInfo.cardInfoNo} />
                              <div className='input-label'>신용카드 유효기간</div>
                              <Input value={selectedData.contractReqInfo.cardInfoValidDur} />
                            </>
                          ) : null
                        }
                        <div className='input-label'>인보이스 필요여부</div>
                        <Input value={
                          selectedData.contractReqInfo.invoiceInfo === 0 ?
                          '인보이스 불필요' :
                          selectedData.contractReqInfo.invoiceInfo === 1 ?
                          '홍콩달러 인보이스' :
                          selectedData.contractReqInfo.invoiceInfo === 2 ?
                          '미국달러 인보이스' :
                          selectedData.contractReqInfo.invoiceInfo === 3 ?
                          '홍콩달러 + 미국달러 인보이스' :
                          selectedData.contractReqInfo.invoiceEtc
                        } />
                        <div className='input-label'>보험증서 발행주소</div>
                        <Input value={selectedData.contractReqInfo.insuranceContractDeliveryAddrType === 0 ? '집' : '사무실'} />
                      </div>
                      <div className='divider' />
                      <div className='modal-body' style={{width: '210px'}}>
                        <div className='input-label'>영문 이름(여권과 동일)</div>
                        <Input value={selectedData.contractReqInfo.enName} />
                        <div style={{marginTop: '16px', gap: '4px', display: 'flex', flexDirection: 'row'}}>
                          <Button onClick={() => window.open(selectedData.contractReqInfo.passportFile.url[0])}>여권 사본</Button>
                          <Button onClick={() => window.open(selectedData.contractReqInfo.rrFile.url[0])}>영문 주민등록 등본</Button>
                        </div>
                        <div className='input-label'>성별</div>
                        <Input value={selectedData.contractReqInfo.gender === 0 ? '남성' : '여성'} />
                        <div className='input-label'>생년월일</div>
                        <Input value={`${selectedData.contractReqInfo.birth.substring(0, 4)}-${selectedData.estimateReqInfo.birth.substring(4, 6)}-${selectedData.estimateReqInfo.birth.substring(6)}`} />
                        <div className='input-label'>흡연여부</div>
                        <Input value={selectedData.contractReqInfo.isSmoke ? '흡연자' : '비흡연자'} />
                        <div className='input-label'>여권번호</div>
                        <Input value={selectedData.contractReqInfo.passportNo} />
                        <div className='input-label'>주소</div>
                        <Input value={selectedData.contractReqInfo.address} />
                        <div className='input-label'>우편번호</div>
                        <Input value={selectedData.contractReqInfo.zipcode} />
                        <div className='input-label'>이메일</div>
                        <Input value={selectedData.contractReqInfo.email} />
                        <div className='input-label'>수익자 영문 이름</div>
                        <Input value={selectedData.contractReqInfo.beneficiaryInfoEnName} />
                        <div className='input-label'>수익자 주민등록번호</div>
                        <Input value={selectedData.contractReqInfo.beneficiaryInfoPNo} />
                        <div className='input-label'>수익자의 피보험자와의 관계</div>
                        <Input value={selectedData.contractReqInfo.beneficiaryInfoRelations} />
                      </div>
                      <div className='divider' />
                      <div className='modal-body' style={{width: '210px'}}>
                        <div className='input-label'>고용주 이름</div>
                        <Input value={selectedData.contractReqInfo.companyInfoCeoName} />
                        <div className='input-label'>업종</div>
                        <Input value={selectedData.contractReqInfo.companyInfoBusType} />
                        <div className='input-label'>회사주소</div>
                        <Input value={selectedData.contractReqInfo.companyInfoAddr} />
                        <div className='input-label'>회사 내 현재 직급</div>
                        <Input value={selectedData.contractReqInfo.companyInfoJobDuty} />
                        <div className='input-label'>현재 회사 근속 기간</div>
                        <Input value={selectedData.contractReqInfo.companyInfoDuration} />
                        <div className='input-label'>연수입 (USD)</div>
                        <Input value={selectedData.contractReqInfo.assetInfoAnnualSalary} />
                        <div className='input-label'>유동자산 (USD)</div>
                        <Input value={selectedData.contractReqInfo.assetInfoTotalAsset} />
                        <div className='input-label'>현재 회사 근속 기간</div>
                        <Input value={selectedData.contractReqInfo.companyInfoDuration} />
                        <div className='input-label'>연수입 (USD)</div>
                        <Input value={selectedData.contractReqInfo.assetInfoAnnualSalary} />
                        <div className='input-label'>유동자산 (USD)</div>
                        <Input value={selectedData.contractReqInfo.assetInfoTotalAsset} />
                      </div>
                    </div>
                    <div className='dividerR'/>
                    <div>청약서 업로드</div>
                    <div style={{height: '8px'}}/>
                    <input type='file' id="files" onChange={handleFiles} />
                  </>
                ) :
                tab === taskStates.CONTRACT ? (
                  <>
                    <div className='dividerR'/>
                    <div>고객 청약서 요청정보</div>
                    <div className='modal-body-split'>
                      <div className='modal-body' style={{width: '210px'}}>
                        <div className='input-label'>원수사</div>
                        <Input value={products.filter((p: any) => p.code === selectedData.productCode)[0].company} />
                        <div className='input-label'>상품명</div>
                        <Input value={products.filter((p: any) => p.code === selectedData.productCode)[0].name} />
                        <div className='input-label'>연납 보험료 (USD)</div>
                        <Input value={selectedData.contractReqInfo.yearlyPaymentUSD} />
                        <div className='input-label'>납입 방식</div>
                        <Input value={selectedData.contractReqInfo.useMonthlyPayment === true ? '월납' : '연납'} />
                        <div className='input-label'>납입 방법</div>
                        <Input value={selectedData.contractReqInfo.paymentMethod === 0 ? '카카오 온라인 트랜스퍼' : selectedData.contractReqInfo.paymentMethod === 1 ? '은행 이체' : selectedData.contractReqInfo.paymentMethod === 2 ? '신용카드 결제' : '신용카드 결제 + 송금'} />
                        {
                          selectedData.contractReqInfo.paymentMethod > 1 ? (
                            <>
                              <div className='input-label'>카드 종류</div>
                              <Input value={selectedData.contractReqInfo.cardInfoType === 0 ? 'Visa' : selectedData.contractReqInfo.cardType === 1 ? 'Master' : 'American Express'} />
                              <div className='input-label'>신용카드 번호</div>
                              <Input value={selectedData.contractReqInfo.cardInfoNo} />
                              <div className='input-label'>신용카드 유효기간</div>
                              <Input value={selectedData.contractReqInfo.cardInfoValidDur} />
                            </>
                          ) : null
                        }
                        <div className='input-label'>인보이스 필요여부</div>
                        <Input value={
                          selectedData.contractReqInfo.invoiceInfo === 0 ?
                          '인보이스 불필요' :
                          selectedData.contractReqInfo.invoiceInfo === 1 ?
                          '홍콩달러 인보이스' :
                          selectedData.contractReqInfo.invoiceInfo === 2 ?
                          '미국달러 인보이스' :
                          selectedData.contractReqInfo.invoiceInfo === 3 ?
                          '홍콩달러 + 미국달러 인보이스' :
                          selectedData.contractReqInfo.invoiceEtc
                        } />
                        <div className='input-label'>보험증서 발행주소</div>
                        <Input value={selectedData.contractReqInfo.insuranceContractDeliveryAddrType === 0 ? '집' : '사무실'} />
                      </div>
                      <div className='divider' />
                      <div className='modal-body' style={{width: '210px'}}>
                        <div className='input-label'>영문 이름(여권과 동일)</div>
                        <Input value={selectedData.contractReqInfo.enName} />
                        <div style={{marginTop: '16px', gap: '4px', display: 'flex', flexDirection: 'row'}}>
                          <Button onClick={() => window.open(selectedData.contractReqInfo.passportFile.url[0])}>여권 사본</Button>
                          <Button onClick={() => window.open(selectedData.contractReqInfo.rrFile.url[0])}>영문 주민등록 등본</Button>
                        </div>
                        <div className='input-label'>성별</div>
                        <Input value={selectedData.contractReqInfo.gender === 0 ? '남성' : '여성'} />
                        <div className='input-label'>생년월일</div>
                        <Input value={`${selectedData.contractReqInfo.birth.substring(0, 4)}-${selectedData.estimateReqInfo.birth.substring(4, 6)}-${selectedData.estimateReqInfo.birth.substring(6)}`} />
                        <div className='input-label'>흡연여부</div>
                        <Input value={selectedData.contractReqInfo.isSmoke ? '흡연자' : '비흡연자'} />
                        <div className='input-label'>여권번호</div>
                        <Input value={selectedData.contractReqInfo.passportNo} />
                        <div className='input-label'>주소</div>
                        <Input value={selectedData.contractReqInfo.address} />
                        <div className='input-label'>우편번호</div>
                        <Input value={selectedData.contractReqInfo.zipcode} />
                        <div className='input-label'>이메일</div>
                        <Input value={selectedData.contractReqInfo.email} />
                        <div className='input-label'>수익자 영문 이름</div>
                        <Input value={selectedData.contractReqInfo.beneficiaryInfoEnName} />
                        <div className='input-label'>수익자 주민등록번호</div>
                        <Input value={selectedData.contractReqInfo.beneficiaryInfoPNo} />
                        <div className='input-label'>수익자의 피보험자와의 관계</div>
                        <Input value={selectedData.contractReqInfo.beneficiaryInfoRelations} />
                      </div>
                      <div className='divider' />
                      <div className='modal-body' style={{width: '210px'}}>
                        <div className='input-label'>고용주 이름</div>
                        <Input value={selectedData.contractReqInfo.companyInfoCeoName} />
                        <div className='input-label'>업종</div>
                        <Input value={selectedData.contractReqInfo.companyInfoBusType} />
                        <div className='input-label'>회사주소</div>
                        <Input value={selectedData.contractReqInfo.companyInfoAddr} />
                        <div className='input-label'>회사 내 현재 직급</div>
                        <Input value={selectedData.contractReqInfo.companyInfoJobDuty} />
                        <div className='input-label'>현재 회사 근속 기간</div>
                        <Input value={selectedData.contractReqInfo.companyInfoDuration} />
                        <div className='input-label'>연수입 (USD)</div>
                        <Input value={selectedData.contractReqInfo.assetInfoAnnualSalary} />
                        <div className='input-label'>유동자산 (USD)</div>
                        <Input value={selectedData.contractReqInfo.assetInfoTotalAsset} />
                        <div className='input-label'>현재 회사 근속 기간</div>
                        <Input value={selectedData.contractReqInfo.companyInfoDuration} />
                        <div className='input-label'>연수입 (USD)</div>
                        <Input value={selectedData.contractReqInfo.assetInfoAnnualSalary} />
                        <div className='input-label'>유동자산 (USD)</div>
                        <Input value={selectedData.contractReqInfo.assetInfoTotalAsset} />
                      </div>
                    </div>
                    <div className='dividerR'/>
                    <div className='input-label'>현재 상태</div>
                    <Radio.Group onChange={(e) => { setSubStep(e.target.value) }} value={subStep}>
                      <Radio value={1}>보험사 청약서 수신중</Radio>
                      <Radio value={2}>보험사 언더라이팅중</Radio>
                      <Radio value={3}>승금증 제출필요</Radio>
                      <Radio value={4}>보험사 증서 발급중</Radio>
                      <Radio value={5}>청약완료</Radio>
                    </Radio.Group>
                    <div style={{height: '16px'}} />
                    {
                      selectedData.transferFiles && selectedData.transferFiles.map((f: any, i: number) => {
                        return <Button key={String(i)} onClick={() => window.open(selectedData.transferFiles[i].url[0])}>{`송금증 다운로드`}</Button>
                      })
                    }
                    <div style={{display: 'flex'}}>
                      <div style={{marginRight: '12px'}}>
                        <div className='input-label'>증권번호</div>
                        <Input value={insuranceNo} onChange={(e) => setInsuranceNo(e.target.value)} />
                      </div>
                      <div style={{marginRight: '12px'}}>
                        <div className='input-label'>청약일 (YYYY.MM.DD)</div>
                        <Input value={contractDate} onChange={(e) => setContractDate(e.target.value)} />
                      </div>
                      <div>
                        <div className='input-label'>청약철회가능일 (마지막날, YYYY.MM.DD)</div>
                        <Input value={limitCancelDate} onChange={(e) => setLimitCancelDate(e.target.value)} />
                      </div>
                    </div>
                    <div style={{display: 'flex'}}>
                      <div style={{marginRight: '12px'}}>
                        <div className='input-label'>예상 커미션 (HKD)</div>
                        <Input value={fee} onChange={(e) => setFee(e.target.value)} />
                      </div>
                      <div style={{marginRight: '12px'}}>
                        <div className='input-label'>예상 커미션 지급일 (YYYY.MM.DD)</div>
                        <Input value={feeDate} onChange={(e) => setFeeDate(e.target.value)} />
                      </div>
                    </div>
                    <div className='dividerR'/>
                  </>
                ) :
                tab === taskStates.COMPLETE ? (
                  null
                ) : null
              }
            </div>
          )
        }
      </Modal>
      <IFANav />
      <div className='page-title'>고객관리</div>
      <div className='page-top-menu-wrap'>
        <div className='page-filter-wrap'>
          <Input placeholder="고객 검색" prefix={<UserOutlined />} style={{width: 300}} />
          <Button type="primary" icon={<SearchOutlined />}>
            검색
          </Button>
        </div>
        {/* <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setIsModalOpen(true)}>
          고객추가
        </Button> */}
      </div>
      <div style={{display: 'flex', flexDirection: 'row', gap: '20px'}}>
        <Link to='/?tab=ESTIMATE' className={searchParams.get('tab') === 'ESTIMATE' || !searchParams.get('tab') ? 'active' : ''}>
          <div>{`설계서 요청 ${tasks.filter((t: any) => t.step === 'ESTIMATE').length}`}</div>
        </Link>
        <Link to='/?tab=CONTRACT_WRITE' className={searchParams.get('tab') === 'CONTRACT_WRITE' ? 'active' : ''}>
         <div>{`청약서 요청 ${tasks.filter((t: any) => t.step === 'CONTRACT_WRITE').length}`}</div>
        </Link>
        <Link to='/?tab=CONTRACT' className={searchParams.get('tab') === 'CONTRACT' ? 'active' : ''}>
          <div>{`청약서 심사 ${tasks.filter((t: any) => t.step === 'CONTRACT').length}`}</div>
        </Link>
        <Link to='/?tab=COMPLETE' className={searchParams.get('tab') === 'COMPLETE' ? 'active' : ''}>
          <div>{`청약 완료 ${tasks.filter((t: any) => t.step === 'COMPLETE').length}`}</div>
        </Link>
      </div>
      <div className='table-wrap-ifa'>
        {
          tab === 'COMPLETE' ? (
            <Table
              columns={columns2 as any}
              dataSource={tasks.filter((task: any) => task.step === tab).map((v: any) => ({...v, joinStep: `${v.step}_${v.subStep}`}))}
              bordered
              onRow={(record, index) => {
                return {
                    onClick: () => handleRowClick(record),
                    style: {cursor: 'pointer'}
                  }
              }}
              scroll={{ x: 'calc(100vw)' }}
              tableLayout='fixed'
            />
          ) : (
            <Table
              columns={columns1 as any}
              dataSource={tasks.filter((task: any) => task.step === tab).map((v: any) => ({...v, joinStep: `${v.step}_${v.subStep}`}))}
              bordered
              onRow={(record, index) => {
                return {
                    onClick: () => handleRowClick(record),
                    style: {cursor: 'pointer'}
                  }
              }}
              scroll={{ x: 'calc(100vw)' }}
              tableLayout='fixed'
            />
          )
        }
      </div>
    </div>
  );
};

export default IFAPage;