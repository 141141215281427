import { logoAxa, logoGenerali, logoZurich } from './icon_route';

// * 송금시 증권번호와 성함(여권과 동일) 반드시 기입
// 납부 보험료 + Levy + USD$40 추가로 송금 (수취 은행 송금 수수료, 전신료 등의 추가 발생 수수료) // 초과 보험료는 고객님의 FPDA 예치금 계좌에 입금 됨
const accountInfo = {
  axa: {
    bankName: 'The Hong Kong and Shanghai Banking Corporation',
    bankAddress: 'HSBC Main Building, 1 Queen\'s Road Central, Hong Kong',
    bankAccountName: 'AXA China Region Insurance Co Ltd',
    bankCode: '004',
    bankAccount: [
      {addr: '391-91234158', curr: 'HKD'},
      {addr: '391-91234107', curr: 'USD'},
    ],
    swiftCode: 'CITIHKHX',
  },
  zurich: {
    bankName: 'Citibank, N.A. Hong Kong Branch',
    bankAddress: 'Champion Tower, Three Garden Road, Central, Hong Kong',
    bankAccountName: 'Zurich Insurance Life (Hong Kong) Limited',
    bankCode: '006',
    bankAccount: [
      {addr: '499-000966-006', curr: 'HKD'},
      {addr: '499-000966-201', curr: 'USD'},
    ],
    swiftCode: 'HSBCHKHHHKH',
  },
  generali: {
    bankName: 'Citibank, N.A. Hong Kong Branch',
    bankAddress: 'Citibank Tower, Citibank Plaza, 3 Garden Road, Central, Hong Kong',
    bankAccountName: 'Generali Life (Hong Kong) Limited',
    bankCode: '006',
    bankAccount: [
      {addr: '391-62520091', curr: 'HKD'},
      {addr: '391-62520113', curr: 'USD'},
    ],
    swiftCode: 'CITIHKHX',
  },
};

const products = [{
  logo: logoAxa,
  name: 'Wealth Advance Savings Series II - Ultimate',
  code: 'AXA_ULTIMATE',
  durations: [5, 10], // pdf
  allowMonthlyPaid: false,
  minForYear: 15000, // pdf
  minForMonth: 0,
  bankInfo: accountInfo.axa,
  availableAge: '0세 ~ 60세',
  benefitTerm: '100세까지',
  launchingYear: '2018', // 추정
  homepageLink: 'https://www.axa.com.hk/en/wealth-advance-saving-series-ultimate',
  fulfillment: 'https://www.axa.com.hk/en/fulfilment-ratios-total-value-ratios-wealth-advance-savings-series-ii-ultimate#?Tab$ratios=tab-fulfilment-ratios-wealth-advance-savings-series-ii-ultimate',
  company: 'AXA',
  companyHomepage: 'https://www.axa.com.hk/',
  companyInfo: 'AXA 그룹의 일원으로서 우리는 보험, 자산 관리 및 은퇴 계획 분야의 글로벌 리더가 되기 위한 승리 전략을 추진하는 일련의 정의된 가치와 헌신을 공유합니다. 19세기 초에 설립된 AXA 그룹은 전 세계 9,400만 명의 고객에게 51개 시장에서 147,000명의 직원이 서비스를 제공하고 있습니다. AXA 그룹의 등록 수익은 1,027억 유로(약 HKD8,719억)에 달했고, 관리 자산은 9,460억 유로(약 HKD8,0315억)에 달했습니다.'
}, {
  logo: logoAxa,
  name: 'Wealth Ultra Savings Plan',
  code: 'AXA_ULTRA',
  durations: [8],
  allowMonthlyPaid: false,
  minForYear: 5000,
  minForMonth: 0,
  bankInfo: accountInfo.axa,
  availableAge: '0세 ~ 60세',
  benefitTerm: '138세까지',
  launchingYear: '2020', // 추정
  homepageLink: 'https://www.axa.com.hk/en/wealth-ultra-savings-plan',
  fulfillment: 'https://www.axa.com.hk/en/fulfilment-ratios-total-value-ratios-wealth-ultra-savings-plan',
  company: 'AXA',
  companyHomepage: 'https://www.axa.com.hk/',
  companyInfo: 'AXA 그룹의 일원으로서 우리는 보험, 자산 관리 및 은퇴 계획 분야의 글로벌 리더가 되기 위한 승리 전략을 추진하는 일련의 정의된 가치와 헌신을 공유합니다. 19세기 초에 설립된 AXA 그룹은 전 세계 9,400만 명의 고객에게 51개 시장에서 147,000명의 직원이 서비스를 제공하고 있습니다. AXA 그룹의 등록 수익은 1,027억 유로(약 HKD8,719억)에 달했고, 관리 자산은 9,460억 유로(약 HKD8,0315억)에 달했습니다.'

},
{
  logo: logoGenerali,
  name: 'LionTycoon Beyond 2',
  code: 'GENERALI_LT',
  durations: [2],
  allowMonthlyPaid: false,
  minForYear: 10000,
  minForMonth: 0,
  bankInfo: accountInfo.generali,
  availableAge: '15일 ~ 75세',
  benefitTerm: '138세까지',
  launchingYear: '확인안됨', // 추정
  homepageLink: 'https://www.generali.com.hk/EN_US/savings-and-retirement/liontycoon-beyond-2',
  fulfillment: '',
  company: 'GENERALI',
  companyHomepage: 'https://www.generali.com.hk/',
  companyInfo: '1981년 Assicurazioni Generali S.p.A는 처음으로 홍콩에서 공인 보험사로 등록되었으며, 2016년 Generali Life(Hong Kong) Limited를 통해 생명 보험 부문으로 사업을 확장했습니다. 현지 지식과 Generali Group의 글로벌 전문 지식을 결합하여 고객의 요구 사항을 충족하는 독특하고 혁신적인 생명 보험, 일반 보험, 전문 보험 및 직원 복리후생 솔루션을 개발합니다.'
},
{
  logo: logoZurich,
  name: 'Swiss Fortune Universal Life Plan',
  code: 'ZURICH_01',
  durations: [5, 10],
  allowMonthlyPaid: true,
  minForYear: 4800,
  minForMonth: 400,
  bankInfo: accountInfo.zurich,
  availableAge: '15일 ~ 75세',
  benefitTerm: '100세까지',
  launchingYear: '2023', // 추정
  homepageLink: 'https://lifeuat.zurich.com.hk/en/products/swiss-fortune-universal-life-plan/',
  fulfillment: 'https://www.zurich.com.hk/-/media/project/zwp/hongkong/docs/customer-services/life-policy-service/zlihk/important-information/zlihk-fulfillment-ratio-for-2023-shelved-products.pdf?rev=8f3126d1214f4a17976bf49fe67bedd9&hash=7A0538F6B1C2E48B4A196009988DDA87',
  company: 'ZURICH',
  companyHomepage: 'https://www.zurich.com.hk/',
  companyInfo: '취리히 보험 그룹(Zurich)은 200개 이상의 국가 및 지역에서 개인과 기업에 서비스를 제공하는 선도적인 다계열 보험사입니다. 150년 전에 설립된 취리히는 보험을 변화시키고 있습니다. 보험 보호를 제공하는 것 외에도 취리히는 웰빙을 촉진하고 기후 회복력을 강화하는 것과 같은 예방 서비스를 점점 더 많이 제공하고 있습니다.'

}, ];

export default products;
