export const arrowBackDark = './icon/arrow-back-dark.png';
export const arrowBackLight = './icon/arrow-back-light.png';
export const arrowDownBoldDark = './icon/arrow-down-bold-dark.png';
export const arrowDownBoldLight = './icon/arrow-down-bold-light.png';
export const arrowDownSmallDark = './icon/arrow-down-small-dark.png';
export const arrowDownSmallLight = './icon/arrow-down-small-light.png';
export const arrowRightItemDark = './icon/arrow-right-item-dark.png';
export const arrowRightItemLight = './icon/arrow-right-item-light.png';
export const arrowGoDark = './icon/arrow-go-dark.png';
export const arrowGoLight = './icon/arrow-go-light.png';

export const closeInputDark = './icon/close-input-dark.png';
export const closeInputLight = './icon/close-input-light.png';
export const documentDark = './icon/document-dark.png';
export const documentLight = './icon/document-light.png';
export const eyeCloseDark = './icon/eye-close-dark.png';
export const eyeCloseLight = './icon/eye-close-light.png';
export const eyeOpenDark = './icon/eye-open-dark.png';
export const eyeOpenLight = './icon/eye-open-light.png';
export const homeActiveDark = './icon/home-active-dark.png';
export const homeActiveLight = './icon/home-active-light.png';
export const homeDark = './icon/home-dark.png';
export const homeLight = './icon/home-light.png';
export const profileActiveDark = './icon/profile-active-dark.png';
export const profileActiveLight = './icon/profile-active-light.png';
export const profileDark = './icon/profile-dark.png';
export const profileLight = './icon/profile-light.png';
export const searchDark = './icon/search-dark.png';
export const searchLight = './icon/search-light.png';
export const settingDark = './icon/setting-dark.png';
export const settingLight = './icon/setting-light.png';
export const ticketActiveDark = './icon/ticket-active-dark.png';
export const ticketActiveLight = './icon/ticket-active-light.png';
export const ticketDark = './icon/ticket-dark.png';
export const ticketLight = './icon/ticket-light.png';
export const uploadDark = './icon/upload-dark.png';
export const uploadLight = './icon/upload-light.png';
export const uploadStateCancel = './icon/upload-state-cancel.png';
export const uploadStateCheck = './icon/upload-state-check.png';
export const outlinkDark = './icon/outlink-dark.png';
export const outlinkLight = './icon/outlink-light.png';
export const verticalEqualDark = './icon/vertical-equal-dark.png';
export const verticalEqualLight = './icon/vertical-equal-light.png';
export const exchangeDark = './icon/exchange-dark.png';
export const exchangeLight = './icon/exchange-light.png';
export const arrowFrontDark = './icon/arrow-front-dark.png';
export const arrowFrontLight = './icon/arrow-front-light.png';
export const closeDark = './icon/close-dark.png';
export const closeLight = './icon/close-light.png';
export const downloadDark = './icon/download-dark.png';
export const downloadLight = './icon/download-light.png';
export const addCircleDark = './icon/add-circle-dark.png';
export const addCircleLight = './icon/add-circle-light.png';
export const removeCircleDark = './icon/remove-circle-dark.png';
export const removeCircleLight = './icon/remove-circle-light.png';
export const salesManageDark = './icon/sales-manage-dark.png';
export const salesManageLight = './icon/sales-manage-light.png';
export const salesManageActiveDark = './icon/sales-manage-active-dark.png';
export const salesManageActiveLight = './icon/sales-manage-active-light.png';
export const logoutDark = './icon/logout-dark.png';
export const logoutLight = './icon/logout-light.png';

//sales
export const worksDark = './icon/works-dark.png';
export const worksLight = './icon/works-light.png';
export const worksActiveDark = './icon/works-active-dark.png';
export const worksActiveLight = './icon/works-active-light.png';
export const salesProfileDark = './icon/sales-profile-dark.png';
export const salesProfileLight = './icon/sales-profile-light.png';
export const salesProfileActiveDark = './icon/sales-profile-active-dark.png';
export const salesProfileActiveLight = './icon/sales-profile-active-light.png';
export const customerDark = './icon/customer-dark.png';
export const customerLight = './icon/customer-light.png';
export const customerActiveDark = './icon/customer-active-dark.png';
export const customerActiveLight = './icon/customer-active-light.png';
export const checkDark = './icon/check-dark.png';
export const checkLight = './icon/check-light.png';
export const addUserDark = './icon/add-user-dark.png';
export const addUserLight = './icon/add-user-light.png';
export const sortDark = './icon/sort-dark.png';
export const sortLight = './icon/sort-light.png';
export const idSmallDark = './icon/id-small-dark.png';
export const idSmallLight = './icon/id-small-light.png';
export const moreDark = './icon/more-dark.png';
export const moreLight = './icon/more-light.png';

export const logoDark = './img/logo-dark.png';
export const logoLight = './img/logo-light.png';

export const logoAxa = './img/logo-axa.png';
export const logoGenerali = './img/logo-generali.png';
export const logoSunlife = './img/logo-sunlife.png';
export const logoZurich = './img/logo-zurich.png';

export const logoLoginDark = './img/logo-login-dark.png';
export const logoLoginLight = './img/logo-login-light.png';


//img

export const d3CoinYellow = './img/coin-yellow.png';
export const d3CoinGradient = './img/coin-gradient.png';
export const d3CoinGold = './img/coin-gold.png';
export const d3Document = './img/document.png';
export const d3Target = './img/target.png';
export const d3Award = './img/award.png';
export const d3Wallet = './img/wallet.png';
export const d3CheckGold = './img/check-gold.png';
export const d3CheckBlue = './img/check-blue.png';

// flag
export const flagKr = './icon/flag/kr.png';
export const flagUs = './icon/flag/us.png';
export const flagHk = './icon/flag/hk.png';