import taskStates from "../../configs/task_states";
import { getApp } from "firebase/app";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

const uploadEstimates = async (taskId: string, files: any) => {
  await updateDoc(doc(getFirestore(getApp()), 'kr_user_tasks', taskId), {
    subStep: 1,
    forIfa: false,
    step: taskStates.CONTRACT_WRITE,
    estimateFiles: files,
  });
};

export default uploadEstimates;
