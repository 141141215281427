import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const initFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAICO2YQ5lUi30Z52UXtF3d8LPHumKm7q8",
    authDomain: "insureinapp.firebaseapp.com",
    projectId: "insureinapp",
    storageBucket: "insureinapp.appspot.com",
    messagingSenderId: "554766735240",
    appId: "1:554766735240:web:ae3b36a0db13dfa0110c3d",
    measurementId: "G-HGWPF3YJ8H"
  };
  
  // Initialize Firebase
  const apps = getApps();
  if (apps.length === 0) {
    initializeApp(firebaseConfig);
    // getAnalytics(app);
  }
};

export default initFirebase;
